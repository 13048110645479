import { motion, AnimatePresence } from 'framer-motion';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { utils } from '@mesa-labs/mesa-ui';

function ActiveIndicator() {
  return (
    <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="8" rx="4" fill="#FF8666" />
    </svg>
  );
}

function InactiveIndicator() {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="4" cy="4" r="4" fill="#EAEEF3" />
    </svg>
  );
}

const CarouselContainer = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  max-width: 478px;
  min-height: 400px;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: flex;
  }
`;

const Copy = styled.div`
  display: none;
  font-family: ${(props) => props.theme.fonts.grotesk};
  font-size: 40px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints.Mobile}) {
    font-size: 40px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: block;
    font-size: 56px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Laptop}) {
    font-size: 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Desktop}) {
    font-size: 66px;
  }
`;

const SubCopy = styled.div`
  display: none;
  font-size: 16px;
  margin-bottom: 32px;
  max-width: 478px;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    display: block;
    font-size: 18px;
    line-height: 28px;
  }
`;

const SocialCredibilitySlide = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.Gray10};
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  line-height: 140%;
  margin-bottom: 68px;
`;

const ReviewsFooter = styled.div`
  align-items: center;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  height: 118px;
  left: 0px;
  position: absolute;
  width: 100%;
`;

const NextIcon = styled.img`
  align-self: flex-end;
  cursor: pointer;
`;

const Indicators = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

const Indicator = styled.div`
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0px;
  }
`;

const Slide = styled(motion.div)`
  left: 0px;
  position: absolute;
  top: 0px;
`;

const Stars = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
`;

const StarIcon = styled.img`
  margin-right: 4px;

  &:lasf-of-type {
    margin-right: 0px;
  }
`;

const SlideName = styled.div`
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 8px;
`;

const SlideTitleCompany = styled.div`
  color: ${(props) => props.theme.colors.Gray10};
  font-size: 18px;
  line-height: 156%;
  margin-bottom: 24px;
`;

const SlideReview = styled.div`
  color: ${(props) => props.theme.colors.White};
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.Desktop}) {
    font-size: 18px;
  }
`;

const SlideDetails = [
  {},
  {
    name: 'Melissa S.',
    company: 'Clifford Power',
    review: 'Mesa has unlocked two major partners for us that we previously couldn\'t support due to extended payment terms, which represents meaningful growth for our business. The integration and payment process has been seamless.',
    stars: 5,
  },
  {
    name: 'Barbara Marsh',
    company: 'Seiberlich Trane',
    review: '[The onboarding] was very easy to follow and set up, and I knew from previous ACH setups to sign in and verify micro-deposits. Very easy, thank you.',
    stars: 5,
  },
  {
    name: 'Sabrina W',
    company: 'Southern Perfection Painting',
    review: 'Mesa gets me access to my invoice payments sooner so I can invest in growing my business. This is a huge improvement to my cash flow and operations. The program is working really well for me.',
    stars: 5,
  },
];

function Carousel(): ReactElement {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideDetails = SlideDetails[currentSlide];

  return (
    <CarouselContainer>
      <AnimatePresence initial={false}>
        {currentSlide === 0 && (
          <Slide
            key="mesa-copy-slide"
            variants={{
              enter: {
                x: -200,
                opacity: 0,
              },
              center: {
                x: 0,
                opacity: 1,
              },
              exit: {
                x: -200,
                opacity: 0,
              },
            }}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <Copy>
              Get paid on
              <br />
              your terms.
            </Copy>

            <SubCopy>
              Mesa bridges contract terms,
              {' '}
              allowing you to build capital and ease cash-flow crunches.
            </SubCopy>

            <SocialCredibilitySlide>
              Join over 1,000 vendors and suppliers
              <br />
              across the country in getting paid sooner.
            </SocialCredibilitySlide>
          </Slide>
        )}

        {currentSlide > 0 && (
          <Slide
            key={slideDetails.name}
            variants={{
              enter: {
                x: -200,
                opacity: 0,
              },
              center: {
                x: 0,
                opacity: 1,
              },
              exit: {
                x: -200,
                opacity: 0,
              },
            }}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <SlideName>{slideDetails.name}</SlideName>

            <SlideTitleCompany>
              {slideDetails.company}
            </SlideTitleCompany>

            <Stars>
              {new Array(slideDetails.stars).fill(null).map(() => (
                <StarIcon key={utils.randomInt()} src="/assets/star-icon.svg" />
              ))}
            </Stars>

            <SlideReview>
              “
              {slideDetails.review}
              ”
            </SlideReview>
          </Slide>
        )}
      </AnimatePresence>

      <ReviewsFooter>
        <Indicators>
          {SlideDetails.map((_, idx) => {
            if (idx === currentSlide) {
              return <Indicator key={utils.randomInt()}><ActiveIndicator /></Indicator>;
            }

            return <Indicator key={utils.randomInt()}><InactiveIndicator /></Indicator>;
          })}
        </Indicators>

        <NextIcon src="/assets/next-icon.svg" onClick={() => setCurrentSlide((currentSlide + 1) % SlideDetails.length)} />
      </ReviewsFooter>
    </CarouselContainer>
  );
}

export default Carousel;
