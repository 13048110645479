import React, { ReactElement, Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoadingScreen, useScrollToTop } from '@mesa-labs/mesa-ui';
import AuthRoute from './common/AuthRoute';
import SplitScreen from '../components/auth/common/SplitScreen';

const FaqPage = lazy(() => import('../components/faq'));
const OnboardingRoutes = lazy(() => import('./Onboarding'));
const DashboardRoutes = lazy(() => import('./Dashboard'));
const SignInPage = lazy(() => import('../components/auth/SignIn'));
const ForgotPassword = lazy(() => import('../components/auth/ForgotPassword'));
const ForgotPasswordConfirm = lazy(() => import('../components/auth/ForgotPasswordConfirm'));
const ForgotPasswordSubmit = lazy(() => import('../components/auth/ForgotPasswordSubmit'));
const SignUpPage = lazy(() => import('../components/auth/SignUp'));
const ConfirmAccountPage = lazy(() => import('../components/auth/ConfirmAccount'));

function AppRoutes(): ReactElement {
  useScrollToTop();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route
          path="/faq"
          element={(
            <FaqPage />
          )}
        />

        {/* For backwards compatibility  */}
        <Route
          path="/dashboard/*"
          element={(
            <DashboardRoutes />
          )}
        />

        <Route
          path="/*"
          element={(
            <DashboardRoutes />
          )}
        />

        <Route
          path="/sign-in"
          element={(
            <AuthRoute redirectToSignIn={false} saveQueryStringData>
              <SplitScreen>
                <SignInPage />
              </SplitScreen>
            </AuthRoute>
          )}
        />

        <Route
          path="/forgot-password"
          element={(
            <AuthRoute redirectToSignIn={false}>
              <SplitScreen>
                <ForgotPassword />
              </SplitScreen>
            </AuthRoute>
          )}
        />

        <Route
          path="/forgot-password-confirm"
          element={(
            <AuthRoute redirectToSignIn={false}>
              <ForgotPasswordConfirm />
            </AuthRoute>
          )}
        />

        <Route
          path="/forgot-password-submit"
          element={(
            <AuthRoute redirectToSignIn={false}>
              <SplitScreen>
                <ForgotPasswordSubmit />
              </SplitScreen>
            </AuthRoute>
          )}
        />

        <Route
          path="/sign-up"
          element={(
            <AuthRoute redirectToSignIn={false} saveQueryStringData>
              <SplitScreen>
                <SignUpPage />
              </SplitScreen>
            </AuthRoute>
          )}
        />

        <Route
          path="/confirm"
          element={(
            <AuthRoute redirectToSignIn={false} saveQueryStringData>
              <ConfirmAccountPage />
            </AuthRoute>
          )}
        />

        <Route
          path="onboarding/*"
          element={(
            <OnboardingRoutes />
          )}
        />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
