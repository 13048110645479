import React, { ReactElement, useMemo } from 'react';
import styled from 'styled-components';

import { useFeatureFlags } from '../../../contexts/FeatureFlagProvider';
import Carousel from './Carousel';
import { useSelector } from '../../../redux/hooks';

const LeftWidthTablet = '45%';
const LeftWidthDesktop = '50%';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    flex-direction: row;
  }
`;

const Logo = styled.img<{ height?: number }>`
  align-self: center;
  display: flex;
  height: ${(props) => props.height || 19}px;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    align-self: flex-start;
    margin-bottom: 150px;
  }
`;

const LeftContainer = styled.div`
  align-items: left;
  background-color: ${(props) => props.theme.colors.Midnight};
  background: url(assets/line-wave-pattern.svg) left top;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${(props) => props.theme.colors.White};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 720px;
  min-width: 352px;
  padding: 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    flex-basis: ${LeftWidthTablet};
    height: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Desktop}) {
    flex-basis: ${LeftWidthDesktop};
    padding: 48px 120px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  overflow-y: auto;
  position: relative;
`;

const RightContent = styled.div`
  padding: 20px 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.Mobile}) {
    padding: 20px 30px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Laptop}) {
    padding: 30px 40px 32px 40px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.Desktop}) {
    padding: 40px 50px;
  }
`;

const SmallCopy = styled.div`
  align-items: center;
  color: #667085;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  line-height: 20px;
  width: 100%;
  justify-content: space-between;
  bottom: 0px;
  left: 0px;
  line-height: 20px;
  padding: 54px 32px 32px 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.Tablet}) {
    font-size: 14px;
  }
`;

type SplitScreenProps = {
  children: ReactElement | ReactElement[];
};

function SplitScreen({ children }: SplitScreenProps): ReactElement {
  const date = useMemo(() => (new Date()).getUTCFullYear(), []);
  const featureFlags = useFeatureFlags();

  const brand = useSelector((state) => state.ui.brand);
  const logoSrc = useMemo(() => {
    if (process.env.NODE_ENV == 'production') {
      return 'mesa-white.svg';
    }

    switch (brand) {
      case 'cardinal':
        return 'mesa-cardinal-white.svg';
      default:
        return 'mesa-white.svg';
    }
  }, [brand]);
  const logoHeight = useMemo(() => {
    if (process.env.NODE_ENV == 'production') {
      return 19;
    }

    switch (brand) {
      case 'cardinal':
        return 25;
      default:
        return 19;
    }
  }, [brand]);

  return (
    <MainContainer>
      <LeftContainer>
        <Logo src={`/assets/${logoSrc}`} height={logoHeight} />

        <Carousel />
      </LeftContainer>

      <RightContainer>
        <RightContent>
          {children}
        </RightContent>

        <SmallCopy>
          <div>
            &#169; Mesa Financial, Inc.
            {' '}
            {date}
          </div>
          {featureFlags.testEnvironment && (
            <div>Test Environment</div>
          )}
          support@joinmesa.com
        </SmallCopy>
      </RightContainer>
    </MainContainer>
  );
}

export default SplitScreen;
